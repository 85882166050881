.h-fit {
    height: fit-content;
}

.flex-75 {
    flex: 0.75 1 0%;
}

.min-w-120 {
    min-width: 30rem;
}

.min-w-160 {
    min-width: 40rem;
}

.min-w-200 {
    min-width: 50rem;
}

.w-rem {
    min-width: 3.1rem;
}

.form-control {
    @apply border border-gray-300 text-gray-900 rounded-md px-3 py-2 focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary max-w-full transition;
}

.form-label {
    @apply text-left block text-sm text-gray-600;
}

.btn {
    @apply inline-flex justify-center py-2 px-4 border-transparent font-medium rounded-md text-white bg-primary border-2 border-primary hover:border-primary-dark hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary transition;
}

.btn-secondary {
    @apply inline-flex justify-center py-2 px-4 border-transparent font-medium rounded-md text-white bg-secondary border-2 border-secondary hover:border-secondary-dark hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary transition;
}

.btn-submit {
    @apply inline-flex justify-center py-2 px-4 border-transparent font-medium rounded-md text-white bg-secondary border-2 border-secondary hover:border-secondary-dark hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary transition;
}

.btn-outline {
    @apply inline-flex justify-center py-2 px-4 font-medium rounded-md text-primary border-2 border-primary hover:border-primary-dark hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-primary transition;
}

.btn-lg {
    @apply inline-flex justify-center py-2 border border-transparent rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary transition text-lg font-normal px-12;
}

.section-label {
    @apply sm:w-2/6 text-lg font-medium text-gray-600 pt-4 pb-2;
}

.form-card {
    @apply bg-white shadow-md sm:w-4/6 px-6 py-5;
}

.review-section {
    @apply mx-10 my-7;
}

.review-section h4 {
    @apply text-tertiary text-lg font-bold mt-6;
}

.review-section h5 {
    @apply text-gray-500 font-bold mt-3;
    font-size: 1.03rem;
}

.review-section p {
    @apply sm:flex my-3.5 sm:space-x-4;
}

.review-section dt {
    @apply font-medium sm:w-2/6 text-gray-500;
}

.review-section dd {
    @apply text-gray-900 sm:w-4/6 overflow-hidden overflow-ellipsis;
}

.strip {
    @apply mt-4 flex justify-center flex-wrap space-x-3 md:space-x-6;
}

.strip div {
    @apply my-2.5;
}

.strip .text-gray-600 span:nth-child(1) {
    @apply bg-primary text-white px-3 py-2 rounded-md mr-2;
}

.strip .text-gray-400 span:nth-child(1) {
    @apply px-3 py-2 rounded-md mr-1 text-gray-500;
}
